import React from "react";
import { Resistor } from "../components/resistor";
import { useBands } from "./useBands";

export const App = ({}) => {

    const [bands, setBands] = useBands();

    return (
        <Resistor bands={bands} setBands={setBands} />
    )
}
