interface ColorDefinition {
    color: string,
    digit: number,
    multiplier: number,
    shortcut?: string;
};

type NameToColor = {
    [color: string]: ColorDefinition
}

export const COLORS_TO_BANDS: NameToColor = {
    black: {
        color: "#000",
        digit: 0,
        multiplier: 1,
        shortcut: "k"
    },
    brown: {
        color: "#964B00",
        digit: 1,
        multiplier: 10,
        shortcut: "n"
    },
    red: {
        color: "#F00",
        digit: 2,
        multiplier: 100,
        shortcut: "r"
    },
    orange: {
        color: "#ffa500",
        digit: 3,
        multiplier: 1000,
        shortcut: "o"
    },
    yellow: {
        color: "#FF0",
        digit: 4,
        multiplier: 10 * 1000,
        shortcut: "y"
    },
    green: {
        color: "#0F0",
        digit: 5,
        multiplier: 100 * 1000,
        shortcut: "g",
    },
    blue: {
        color: "#00F",
        digit: 6,
        multiplier: 1 * 1000 * 1000,
        shortcut: "b"
    },
    violet: {
        color: "#7f00ff",
        digit: 7,
        multiplier: NaN,
        shortcut: "v",
    },
    grey: {
        color: "#AAAAAA",
        digit: 8,
        multiplier: NaN,
        shortcut: "e",
    },
    white: {
        color: "#FFF",
        digit: 9,
        multiplier: NaN,
        shortcut: "w"
    },

    gold: {
        color: "#D4AF37",
        digit: NaN,
        multiplier: NaN,
    }
}

export const SHORTCUT_TO_COLOR = Object.keys(COLORS_TO_BANDS).reduce((agg, key) => {
    if (!COLORS_TO_BANDS[key].shortcut) {
        return agg;
    }
    return {
        ...agg,
        [COLORS_TO_BANDS[key].shortcut]: key,
    }
}, {});

export const getNextColor = (color: string) => {
    const bandsLength = Object.keys(COLORS_TO_BANDS).length - 1; // not counting gold
    return Object.keys(COLORS_TO_BANDS)[(Object.keys(COLORS_TO_BANDS).findIndex(c => c === color) + 1) % bandsLength];
}

export const getPrevColor = (color: string) => {
    const bandsLength = Object.keys(COLORS_TO_BANDS).length - 1; // no counting gold
    let val = Object.keys(COLORS_TO_BANDS).findIndex(c => c === color) - 1);
    if (val < 0) {
        val = bandsLength - 1;
    }
    return Object.keys(COLORS_TO_BANDS)[val];
}