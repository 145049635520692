import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { COLORS_TO_BANDS, getNextColor, getPrevColor } from "../../utils/bands";

enum BAND_COLORS {
    RED = "red",
    GOLD = "gold",
    BLUE = "blue",
    BLACK = "black"
}

type ColorsToHexType = { [c: string]: string }

const COLORS_TO_HEX: ColorsToHexType = {
    "red": "#F00",
    "blue": "#00F",
    "black": "#000",


    "gold": "#D4AF37"
}

const ResistorContainer = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
`;

const ResistorStyled = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    margin: 0 auto;
`;

const SIZE = 100;

const SHADOW = 15;

const ResistorEnd = styled.div<{zIndex: number}>`
    width: ${SIZE}px;
    height: ${SIZE}px;
    background: #e8c990;
    border-radius: 50%;

    display: flex;
    align-items: center;
    position: relative;
    z-index: ${({zIndex}) => zIndex};
    box-shadow: inset ${SHADOW}px ${SHADOW}px 0 0 rgba(0,0,0, 0.2);
`;

const ResistorCenter = styled.div`
    width: ${SIZE*1.5}px;
    height: ${SIZE*0.8}px;
    background: #e8c990;
    margin-left: -${SIZE/4}px;
    margin-right: -${SIZE/4}px;
    display: flex;
    align-content: flex-end;
    padding: 0 ${SIZE * 0.2}px;
    box-shadow: inset 0 ${SHADOW}px 0 0 rgba(0,0,0, 0.2);
`;

type ColorProp = {
    color: string,
}

type InteractiveBandProps = {
    onChange: (color: string) => void
} & ColorProp;

const ResistorBandInteractive = ({ color, onChange }): InteractiveBandProps => {

    const ref = useRef();

    const onWheel = (e: WheelEvent) => {
        if (e.deltaY > 0) {
            onChange(getNextColor(color));
        } else {
            onChange(getPrevColor(color));
        }
    }

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        (ref.current as unknown as HTMLDivElement).addEventListener("wheel", onWheel);

        return () => (ref.current as unknown as HTMLDivElement).removeEventListener("wheel", onWheel);
    }, [ ref, color, onChange ]);

    return <ResistorBand ref={ref} color={color} />
}

const ResistorBand = styled.div<ColorProp>`
    width: 10px;
    height: calc(100% - ${SHADOW}px);
    background: ${({ color }) => COLORS_TO_BANDS[color].color};
    display: inline-block;
    margin: 0 auto;
    margin-top: auto;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        background: ${({ color }) => COLORS_TO_BANDS[color].color};
        display: block;
        width: 10px;
        height: 15px;
        top: -15px;
        left: -2px;
        transform: skew(15deg);
        box-shadow: inset 0 0 100px 0 rgba(0,0,0,0.1);
    }
`;

const ResistorValue = styled.div`
    font-size: 30px;
    font-family: sans-serif;
    margin-top: 2em;
`;

interface Props {
    bands: string[],
    setBands: (bands: string[]) => void
}

const bandsToResisitorValue = (bands: string[]): number => {
    return (10 * COLORS_TO_BANDS[bands[0]].digit + COLORS_TO_BANDS[bands[1]].digit) * COLORS_TO_BANDS[bands[2]].multiplier;
}

const resistanceToString = (resistance: number): string => {
    if (resistance >= 1000000) {
        return (resistance / 1000000) + "M Ω";
    }
    if (resistance >= 1000) {
        return (resistance / 1000) + "K Ω";
    }
    return resistance + " Ω";
}

export const Resistor = ({ bands, setBands }: Props) => {

    const setBand = (i: number) => (color: string) => {
        const newBands = [...bands];
        newBands[i] = color;
        setBands(newBands);
    }

    return (
        <ResistorContainer>
            <ResistorStyled>
                <ResistorEnd zIndex={1}>
                    <ResistorBandInteractive color={bands[0]} onChange={setBand(0)} />
                </ResistorEnd>
                <ResistorCenter>
                    <ResistorBandInteractive color={bands[1]} onChange={setBand(1)} />
                    <ResistorBandInteractive color={bands[2]} onChange={setBand(2)}  />
                </ResistorCenter>
                <ResistorEnd zIndex={-1}>
                    <ResistorBandInteractive color={bands[3]} onChange={() => {}} />
                </ResistorEnd>
            </ResistorStyled>
            <ResistorValue>
                {resistanceToString(bandsToResisitorValue(bands))}
            </ResistorValue>
        </ResistorContainer>
    )
}