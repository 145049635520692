import React, { useState, useEffect } from "react";
import { SHORTCUT_TO_COLOR } from "../utils/bands";

// const codeToKey = {
//     "r": "red",
//     "g": "green",
//     "b": "blue",
//     "k": "black",
//     "n": "brown",
//     "o": "orange"
// }

export const useBands = () => {
    const [nextBand, setNextBand] = useState(0);
    const [bands, setBands] = useState(["red", "red", "red", "gold"]);

    const onKeyDown = (e: KeyboardEvent) => {
        const key = e.key.toLowerCase();
        console.log(key);
        if (SHORTCUT_TO_COLOR[key]) {
            const newBands = [...bands];
            newBands[nextBand] = SHORTCUT_TO_COLOR[key];
            setNextBand((nextBand+1) % 3);
            setBands(newBands);
        }

        if (key === "escape") {
            setNextBand(0);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        }
    }, [bands, nextBand]);

    useEffect(() => {
        const handle = setTimeout(() => {
            setNextBand(0);
        }, 10 * 1000);
        
        return () => {
            clearTimeout(handle);
        }
    })

    return [bands, setBands];
}